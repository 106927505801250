import { getTranslatedProperty } from '@shopware-pwa/helpers-next';
import type {
  LineItem,
  Product,
  PropertyGroup,
} from '@shopware-pwa/types/shopware-6-client';

export const getItemVariantString = (color: string, size: string) =>
  `Colour ${color} - Size ${size}`;

type ProductOptions = 'Farbe' | 'Größe';

type TrackingEvent =
  | 'view_item_list'
  | 'view_item'
  | 'add_to_wishlist'
  | 'add_to_cart'
  | 'remove_from_cart'
  | 'view_cart'
  | 'begin_checkout'
  | 'add_shipping_info'
  | 'add_payment_info'
  | 'purchase';

export const getOptionName = (
  optionGroups: PropertyGroup[],
  desiredOption: ProductOptions,
  selectedOptions: any,
) => {
  if (!optionGroups || !selectedOptions) return;

  const group = optionGroups.find((group) => group.name === desiredOption);
  if (!group || !group.options) return;

  const foundOption = group.options.find(
    (option) => option.id === selectedOptions[desiredOption],
  );
  return foundOption ? getTranslatedProperty(foundOption, 'name') : undefined;
};

const region = useCookie('region')
const country = useCookie('country')



export const useGtmTrackingWithProduct = (
  totalPrice: number | null,
  event: TrackingEvent,
  items: LineItem[] | Product[],
  moreInfo: {
    item_categories?: (string | null)[];
    num_items?: number;
    transactionId?: string;
    tax?: string;
    shipping?: number;
    item_list_name?: string;
    ecomm_pagetype?:
      | 'home'
      | 'searchresults'
      | 'category'
      | 'product'
      | 'basket'
      | 'purchase'
      | 'other';
  } = {},
  variant?: any,
) => {
  if (import.meta.client) {
    try {
      const gtm = useGtm();
      const eventData = {
        value: totalPrice,
        currency: 'EUR',
        event,
        items: getMappedItems(items, variant),
        ...moreInfo,
        contents: [
          {
            id: items[0].apiAlias === 'product' ? items[0].productNumber : null,
            quantity: 1,
          },
        ],
      };
      gtm?.trackEvent(eventData);
    } catch (error) {
      console.warn(
        `There was a tracking error with event: ${event}. Error: ${error}`,
      );
    }
  }
};

const getMappedItems = (items: LineItem[] | Product[], variant?: any) => {
  return items.map((item) => {
    const newItem: any = [
      'order_line_item',
      'cart_line_item',
      'line_item',
    ].includes(item.apiAlias)
      ? lineItem(item as LineItem)
      : productItem(item as Product, variant);
    newItem['item_brand'] = 'super.natural';
    return newItem;
  });
};

const productItem = (product: Product, variant: any) => ({
  item_variant: getItemVariantString(variant.itemColor, variant.itemSize),
  item_name: getTranslatedProperty(product, 'name'),
  price: product.calculatedPrice.totalPrice,
  item_id: product.productNumber,
  quantity: 1,
});

const lineItem = (lineItem: LineItem) => ({
  item_variant: getItemVariantString(
    lineItem.payload?.options?.[0]?.option,
    lineItem.payload?.options?.[1]?.option,
  ),
  item_name: lineItem.label,
  item_id: lineItem?.payload?.productNumber,
  price: lineItem.price?.totalPrice ?? lineItem.priceDefinition?.price,
  quantity: lineItem.quantity,
});

